// Glyphicons font path
$icon-font-path:        "../fonts/";

$screen-xl: 						3600px !default;
$screen-xl-min: 				$screen-xl !default;
$grid-float-breakpoint: $screen-xl !default;

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         rgb(232, 84, 17);
$brand-primary-alpha:   rgba(232, 84, 17, 0.85);
$orange:								$brand-primary;

$brand-accent:					rgb(85, 86, 90);
$dark-grey:							$brand-accent;

$text-colour:						rgb(85, 86, 90);
$mid-grey:							rgb(203, 203, 203);
$light-grey:						rgb(245, 244, 245);

$white:									rgb(255,255,255);

// Fonts
$sans-serif-font:				'Lato', sans-serif;
$serif-font:						'Lato', sans-serif;

$column-count:					3;
$column-width:					300px;