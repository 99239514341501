.align-images {
  img {
    height: 100px;
    width: auto;
  }
}


.small-text {
  .small {
    font-size: 70% !important;
  }
}

.chart-headers {
  width: 100%;
  .chart-header {
    font-size: 2rem;
    font-weight: 600;
    .column-header {
      font-size: 5rem;
    }
  }
  img {
    height: 180px !important;
    width: auto !important;
  }
}

.large-text {
  * {
    font-size: 3.1rem;
    line-height: 1.2;
    max-width: 85%;
  }
}

.extra-column {
  .row {
    @extend .d-block, .d-lg-flex;
  }
}
