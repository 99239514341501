// Grid system
.main {
  @include make-sm-column($main-sm-columns);
  .sidebar-primary & {
    @include make-sm-column($main-sm-columns - $sidebar-sm-columns);
  }
}
.sidebar {
  @include make-sm-column($sidebar-sm-columns);
}


.col-sm-1-5{
  @include make-sm-column(2.4)
}
.col-md-1-5{
  @include make-md-column(2.4)
}
.col-lg-1-5{
  @include make-lg-column(2.4)
}
.col-sm-2-5{
  @include make-sm-column(4.8)
}
.col-md-2-5{
  @include make-md-column(4.8)
}
.col-lg-2-5{
  @include make-lg-column(4.8)
}
.col-sm-3-5{
  @include make-sm-column(7.2)
}
.col-md-3-5{
  @include make-md-column(7.2)
}
.col-lg-3-5{
  @include make-lg-column(7.2)
}
.col-sm-4-5{
  @include make-sm-column(9.6)
}
.col-md-4-5{
  @include make-md-column(9.6)
}
.col-lg-4-5{
  @include make-lg-column(9.6)
}

.col-sm-push-1-5{
  @include make-sm-column-push(2.4)
}
.col-sm-pull-1-5{
  @include make-sm-column-pull(2.4)
}
.col-sm-offset-1-5{
  @include make-sm-column-offset(2.4)
}
.col-md-push-1-5{
  @include make-md-column-push(2.4)
}
.col-md-pull-1-5{
  @include make-md-column-pull(2.4)
}
.col-md-offset-1-5{
  @include make-md-column-offset(2.4)
}
.col-lg-push-1-5{
  @include make-lg-column-push(2.4)
}
.col-lg-pull-1-5{
  @include make-lg-column-pull(2.4)
}
.col-lg-offset-1-5{
  @include make-lg-column-offset(2.4)
}

.col-sm-push-2-5{
  @include make-sm-column-push(4.8)
}
.col-sm-pull-2-5{
  @include make-sm-column-pull(4.8)
}
.col-sm-offset-2-5{
  @include make-sm-column-offset(4.8)
}
.col-md-push-2-5{
  @include make-md-column-push(4.8)
}
.col-md-pull-2-5{
  @include make-md-column-pull(4.8)
}
.col-md-offset-2-5{
  @include make-md-column-offset(4.8)
}
.col-lg-push-2-5{
  @include make-lg-column-push(4.8)
}
.col-lg-pull-2-5{
  @include make-lg-column-pull(4.8)
}
.col-lg-offset-2-5{
  @include make-lg-column-offset(4.8)
}

.col-sm-push-3-5{
  @include make-sm-column-push(7.2)
}
.col-sm-pull-3-5{
  @include make-sm-column-pull(7.2)
}
.col-sm-offset-3-5{
  @include make-sm-column-offset(7.2)
}
.col-md-push-3-5{
  @include make-md-column-push(7.2)
}
.col-md-pull-3-5{
  @include make-md-column-pull(7.2)
}
.col-md-offset-3-5{
  @include make-md-column-offset(7.2)
}
.col-lg-push-3-5{
  @include make-lg-column-push(7.2)
}
.col-lg-pull-3-5{
  @include make-lg-column-pull(7.2)
}
.col-lg-offset-3-5{
  @include make-lg-column-offset(7.2)
}

.col-sm-push-4-5{
  @include make-sm-column-push(9.6)
}
.col-sm-pull-4-5{
  @include make-sm-column-pull(9.6)
}
.col-sm-offset-4-5{
  @include make-sm-column-offset(9.6)
}
.col-md-push-4-5{
  @include make-md-column-push(9.6)
}
.col-md-pull-4-5{
  @include make-md-column-pull(9.6)
}
.col-md-offset-4-5{
  @include make-md-column-offset(9.6)
}
.col-lg-push-4-5{
  @include make-lg-column-push(9.6)
}
.col-lg-pull-4-5{
  @include make-lg-column-pull(9.6)
}
.col-lg-offset-4-5{
  @include make-lg-column-offset(9.6)
}
