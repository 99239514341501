body{
  font-family: $serif-font;
  font-weight: 400;
  color: $dark-grey;
  background-color: $white;
  font-size: 20px;
  height: 100%;
  letter-spacing: .025rem;
}
.wrapper{
  height: 100%;
  overflow-x: hidden;
}
.navbar{
  border:0 none;
}
.navbar-primary {
  margin: 20px;
}
.navbar-nav > li > a{
  color: $white;
}
.affix-top .navbar-nav > li > a{
  text-shadow: 0 0 2px #000, 0 0 2px $brand-primary;
}
.navbar-fixed-top { top: 0px; }
body.admin-bar .navbar-fixed-top { top: 32px !important; }

.nav > li > a:focus, .nav > li > a:hover {
    background-color: transparent;
    text-decoration: underline;
}

h1{
  color: $brand-primary;
  font-weight: 700;
  font-size: 2.8rem;
  small{
    color: $dark-grey;
  }
}
h2{
  font-size: 2.4rem;
  font-weight: 700;
}
h3{
  font-weight: 700;
}
h2, h3, p{
  margin-bottom: 20px;
}
.lead{
  font-size: 2.25rem;
  line-height: inherit;
  font-weight: inherit;
}
ul.lead{
  padding: 0 1em 1em;
}

blockquote{
  border-left: 0;
  font-size: inherit;
  position: relative;
  background-color: $light-grey;
  margin: 70px 0 50px;
  padding: 25px 180px 25px 20px;
  &:after{
    content: '';
    display: block;
    background-image: url(/wp-content/themes/fulcrumieb/dist/images/lightbulb.png);
    background-size: contain;
    position: absolute;
    right: 20px;
    width: 130px;
    height: 175px;
    background-repeat: no-repeat;
    top: -20px;
  }
  p:first-child{
    margin-bottom: 0;
  }
}

.breadcrumb{
  padding: 8px 0;
  margin-bottom: 20px;
  list-style: none;
  background-color: transparent;
  border-radius: 0;
  border-bottom: 2px solid $light-grey;
  font-size: 1.3rem;
  a{
    color: $text-colour;
    &:hover{
      color: $brand-primary;
    }
  }
}

header{
  margin-bottom: 20px;
  position: relative;
  .container{
    position: relative;
  }
}
.jumptop{
  position:absolute;
  top:0;
  left:0
}

.to-top {
  background-color: transparent;
  bottom: 0;
  padding: 5px 10px;
  position: fixed;
  right: 5vw;
  visibility: hidden;
  z-index: 10;
  font-size: 2.2rem;
  a,a:hover{
    color: $brand-primary;
  }
}
.to-top.affix {
  visibility: visible;
}

.brand {
  display: block;
  max-width: 240px;
}

hr {
  border-width: 2px;
  margin-bottom: 30px;
  margin-top: 30px;
  width: 100%;
  border-color: light-grey;
}

.bg-transparent,.bg-white{
  background-color: transparent;
  color: $text-colour;
  a, a:visited{
    color: $brand-primary;
  }
  .h1, .h2, h1, h2{
    color: $text-colour;
  }
  .h3, .h4, .h5, .h6, , h3, h4, h5, h6{
    color: $text-colour;
  }
  .bg-colour-pad {
    padding: 0px;
  }
  .btn.btn-default, .gform_body .button{
    color: white;
    background-color: $brand-primary;
    border-color: white;
  }
  .text{
    .block-title{
      &:before{
        border-bottom-color: $light-grey;
      }
    }
  }
}
.bg-brand-primary{
  background-color: $brand-primary;
  color: white;
  a, a:visited{
    color: white;
  }
  .container, .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    color: white;
  }
  .form-control{
    border: 1px solid white;
    color: white;
  }
  .btn.btn-default, .gform_body .button{
    color: white;
    background-color: $brand-primary;
    border-color: white;
  }
  .text{
    .block-title{
      &:before{
        border-bottom-color: white;
      }
    }
  }
}
.bg-brand-accent,.bg-dark-grey{
  background-color: $dark-grey;
  color: white;
  a, a:visited{
    color: white;
  }
  .container, .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    color: white;
  }
  .form-control{
    border: 1px solid white;
    color: white;
  }
  .btn.btn-default, .gform_body .button{
    color: white;
    background-color: transparent;
    border-color: white;
  }
  hr{
    border-color: white;
  }
  .text{
    .block-title{
      &:before{
        border-bottom-color: $mid-grey;
      }
    }
  }
}
.bg-light-grey{
  background-color: $light-grey;
  color: $text-colour;
  a, a:visited{
    color: $brand-primary;
  }
  .container, .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    color: $dark-grey;
  }
  &.active:focus,.form-control{
    border: 1px solid white;
    color: $text-colour;
  }
  .btn.btn-default, .gform_body .button{
    color: black;
    background-color: transparent;
    border-color: black;
  }
  hr{
    border-color: $mid-grey;
  }
  .text{
    .block-title{
      &:before{
        border-bottom-color: $mid-grey;
      }
    }
  }
}

/* MOBILE NAVBAR */
header{
  .navbar-collapse{
    height: auto !important;
    opacity: 0;
    transition: opacity 0.25s ease
  }
  .navbar-collapse.in{
    margin-bottom:20px;
    opacity: 1;
    transition: opacity 0.25s ease;
  }
  .navbar-toggle {
    position: fixed;
    z-index: 100;
    border-radius: 0;
    margin-right: 0;
    transition: all .5s;
    right: 5vw;
    top: 10px;
    padding: 3px 5px;
    border: 2px solid white;
    .icn-bar{
      background-color: transparent;
      border-radius: 0;
      display: block;
      height: 3px;
      margin: 4px 0;
      width: 22px;
    }
    &:after{
      content: '\2573';
      display: block;
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      font-size: 22px;
      text-align: center;
      color: white;
      font-weight: 300;
    }
    &.collapsed{
      border: 2px solid $mid-grey;
      transition: all .5s;
      background: white;
      &:before{
        content: 'MENU';
        display: block;
        position: absolute;
        width: 100%;
        top: -18px;
        font-size: 11px;
        text-align: center;
        color: $mid-grey;
        font-weight: 700;
        text-transform: uppercase;
        left: 0;
      }
      &:after{
        content: '';
      }
      .icn-bar{
        background-color: $brand-accent;
      }
    }
  }
  .navbar-primary {
    background-color: $brand-primary;
    left: 15px;
    margin: 0;
    min-height: 0;
    position: fixed;
    right: 15px;
    top: 0;
    z-index: 95;
    overflow: hidden;
    ul{
      display: flex;
      flex-direction: column;
      height: 100vh;
      justify-content: center;
      margin: 0;
      li{
        flex: 0 1 auto;
        padding-left: 10%;
        font-size: 3rem;
        font-weight: 700;
        &.active{
          > a{
            color: $mid-grey;
          }
        }
        > a{
          line-height: 1;
          transition: all .5s;
          &:hover{
            text-decoration: none;
            color: $brand-accent;
            transition: all .5s;
          }
        }
      }
    }
  }
  .primary-nav .navbar{
    margin-bottom: 0;
    min-height: 0;
  }
  .primary-nav .navbar-primary {
    margin: 10px 20px;
  }
  .primary-nav .brand img{
    height: 40px;
  }
  .brand.visible-xs-block img {
    max-width: 190px;
    padding: 17px 10px;
    width: auto;
    height: auto !important;
  }
  .primary-nav .navbar-nav > li > a{
    padding: 10px 0;
  }
}

.admin-bar{
  header{
    .navbar-toggle {
      top: 50px;
    }
  }
}



.vertical-align {
  display: table;
  height: 100%;
  margin-bottom: 15px;
  table-layout: fixed;
  width: 100%;
}

.bg-img {
    background-position: center 55%;
    background-repeat: no-repeat;
    background-size: cover;
}

footer {
  text-align: left;
  background-color: $brand-primary;
  color: $white;
  padding: 20px 0;
  a,a:hover{
    color: $white;
    transition: all .5s;
  }
  .navbar-nav > li > a{
    padding-bottom: 0;
    padding-top: 15px;
  }
  .footer-logos{
    img{
      display: inline-block;
      max-height: 50px;
      width: auto;
      margin-left: 20px;
      margin-bottom: 15px;
    }
  }
  .copyright{
    padding-top: 50px;
    font-size: 1.6rem;
  }
}

.img-close{
  opacity: 0.5;
}
.close:focus, .close:hover {
    opacity: 0.8;
}
.btn-default{
  background-color: #818181;
  color: $white;
  border-color: #818181;
}
.terms-conditions {
  line-height: 120%;
  opacity: 0.8;
  h3 {
    font-size: 1.8rem;
  }
}
.checkbox-inline + .checkbox-inline, .radio-inline + .radio-inline{
  margin-left: 0;
}

/*-----------------------------------*\
  $ANIMATIONS
\*-----------------------------------*/
/*@-webkit-keyframes hue {
  0% {
    color: $brand-primary;
  }
  50% {
    color: $brand-accent;
  }
  100% {
    color: $brand-primary;
  }
}*/

.splash {
  margin-top: 0;
  background: linear-gradient(
    to bottom,
    white 55%,
    $light-grey 55%
  );
  display: flex;
  flex-flow: row wrap;
  position: relative;
  overflow-x: hidden;
  .container {
    display: flex;
    min-height: 550px;
    max-height: 800px;
    align-content: flex-start;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-flow: row wrap;
    h1{
      line-height: 1.3;
      small{
        font-weight: inherit;
        margin-bottom: 1em;
        display: block;
      }
    }
    .head-caption{
      flex: 1 1 100%;
      align-self: stretch;
      padding: 5vh 0;
      h1{
        @extend .animated;
        @extend .fadeIn;
        animation-delay: 0;
        animation-duration: 4s;
      }
    }
    .head-image{
      /*@extend .animated;
      @extend .slideInRight;
      animation-delay: 0.25s;
      animation-duration: 1.5s;*/
      flex: 1 1 100%;
      align-self: center;
      padding: 15px 0;
      img{
        margin: 0 auto;
        @extend .animated;
        @extend .fadeIn;
        animation-delay: 0s;
        animation-duration: 3s;
      }
    }
    .jumpcontent{
      display: flex;
      background: white;
      width: 60px;
      height: 60px;
      position: absolute;
      bottom: 20px;
      left: calc(50% - 30px);
      border-radius: 50%;
      font-family: "fontawesome";
      font-size: inherit;
      align-items: center;
      justify-content: center;
      color: $mid-grey;
      font-size: 5rem;
      transition: all .5s;
      &:hover,&:active,&:focus{
        text-decoration: none;
      }
      &:hover{
        color: white;
        transition: all .75s;
        background: $brand-primary;
      }
      &:after{
        content: '\f107';
      }
    }
  }
}

.page-child.parent-what-we-do{
  .splash{
    display: flex;
    align-items: center;
    background: $light-grey;
  }
}


.content-blocks{
  .content-block{
    padding: 60px 0;
  }
  h1{
    font-style: italic;
  }
  .text{
    ul{
      padding-left: 15px;
      li{
        list-style: none;
      }
      li::before {
        content: '●';
        color: $brand-primary;
        display: inline-block;
        width: 1.25em;
        margin-left: -1.25em;
        padding: 0 0 .5em 0;
        font-size: .5em;
        vertical-align: middle;
      }
    }
    .block-title.not-empty {
      font-size: 1.3rem;
      text-transform: uppercase;
      margin-bottom: 30px;
      &:before{
        content: '';
        display: block;
        border-bottom-width: 2px;
        border-bottom-style: solid;
        margin-bottom: 15px;
      }
      p:first-of-type{
        margin-bottom: 0;
      }
    }
    .block-text{
      h2,h3{
        &:first-child{
          margin-top: 0;
        }
      }
    }
  }
  /* special case */
  .block-subnav-grid{
    .subnav-grid{
      display: flex;
      flex-flow: column wrap;
      margin: 30px 0;
      .nav-item{
        flex: 1 1 calc(33% - 100px);
        text-align: center;
        margin: 20px 50px;
        color: $text-colour;
        &:hover{
          color: $brand-primary;
          text-decoration: none;
        }
        img{
          max-height: 100px;
          margin: 0 auto 15px;
        }
      }
    }
  }
  /* special case */
  .block-single-testimonial,.block-testimonial-list{
    .quote{
      font-size: 2.25rem;
      font-style: italic;
      margin-bottom: 25px;
    }
    .detail{
      margin-bottom: 25px;
      .client{
        font-weight: 700;
        color: $brand-primary;
      }
      .company{
        font-weight: 400;
        &:before{
          content: '/';
          display: inline-block;
          margin: 0 10px;
        }
      }
    }
    a{
      font-size: 1.6rem;
      color: $text-colour;
    }
  }
  .block-single-testimonial{
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .block-testimonial-list{
    padding-top: 0;
    .testimonial-grid{
      .testimonial,.grid-sizer{
        background-color: $light-grey;
        padding: 50px 30px 40px;
        margin-bottom: 15px;
        width: 100%;
      }
    }
    .detail{
      margin-bottom: 25px;
      .client{
        font-weight: 700;
        color: $brand-primary;
        display: block;
      }
      .company{
        font-weight: 400;
        display: block;
        &:before{
          content: '';
          margin: 0;
        }
      }
    }
  }
  .block-meet-the-team{
    .person-grid{
      display: flex;
      flex-flow: column wrap;
      align-content: center;
      .person,.contact-box{
        flex: 1 1 auto;
        margin: 0 0 20px;
        width: 300px;
        max-width: 100%;
        .person-photo{
          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;
          height: 200px;
        }
        h4{
          color: white;
          font-weight: 700;
          small{
            display: block;
            font-size: inherit;
            color: inherit;
            font-weight: 400;
          }
        }
      }
      .contact-box{
        background-color: $brand-primary;
        height: 200px;
        padding: 15px;
        font-size: 1.4rem;
      }
    }
  }
  .image_with_caption{
    background-color: transparent;
    height: 100%;
    .block-title{
      text-align: center;
      display: block;
      text-transform: uppercase;
      z-index: 10;
      .title{
        h2{
          color: $dark-grey;
          border-color: $brand-primary;
          margin-top: 0;
        }
      }
    }
    .imageleft,.imageright{
      display: flex;
      flex-wrap: stretch;
      align-items: stretch;
      flex-flow: column nowrap;
      > div[style*='image:url']{
        background-position: center center;
        background-size: cover;
        flex: 1 1 100%;
        height: 45vh;
        margin: 20px 0 0;
        min-height: 300px;
        max-height: 600px;
        width: 100%;
        order: 2;
        position: relative;
        > a{
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 10;
          display: block;
          &:hover{
            background-color: rgba(0,0,0,0.15);
          }
        }
      }
      .image-caption{
        flex: 1 1 100%;
        order: 3;
        padding: 30px 20px;
        z-index: 5;
      }
    }
    .imageright{
      .block-title{
        .spacer{
          order: 2;
        }
        .title{
          order: 1;
        }
      }
    }
  }
  .call_to_action{
    min-height: 500px;
    margin-top: 15px;
    margin-bottom: 15px;
    padding-top: 0px;
    padding-bottom: 0px;
    position: relative;
    overflow-x: hidden;
    .cta-flex{
      display: flex;
      justify-content: center;
    }
    .cta-cols{
      display: flex;
      flex-flow: row wrap;
      margin-left: auto;
      margin-right: auto;
      p:last-child a{
        font-size: 1.6rem;
      }
      h1,h2,h3,h4{
        margin-top: 0;
        padding-top: 0;
      }
      .cta-col{
        flex: 0 0 100%;
        min-height: 300px;
        justify-content: stretch;
        display: flex;
        flex-flow: column;
        position: relative;
        h2{
          font-size: 3.6rem;
        }
      }
      .cta-image{
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center top;
        width: 100%;
        min-height: 300px;
      }
      .cta-content{
        z-index: 30;
        background-color: transparent;
        padding: 30px 5vw;
        display: flex;
        flex-flow: column nowrap;
        flex: 1 1 auto;
        justify-content: space-between;
      }
    }
  }
  .content_signpost{
    background: $light-grey;
    position: relative;
    h3{
      color: $brand-primary;
    }
    a,a:visited{
      color: $text-colour;
    }
    .signpost-cols{
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      .signpost-col{
        flex: 0 0 100%;
        min-height: 300px;
        justify-content: stretch;
        display: flex;
        flex-flow: column;
      }
      .signpost-image{
        display: block;
        width: 260px;
        max-width: 100%;
        height: 180px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: bottom center;
        margin: 0 auto 30px;
      }
      .signpost-content{
        display: flex;
        flex-flow: column nowrap;
        flex: 1 1 auto;
      }
    }
    p:last-child{
      justify-self: flex-end;
      a{
        font-size: 1.6rem;
      }
    }
  }
  .flex-gallery{
    display: flex;
    flex-wrap: wrap;
    > a, > div{
      flex: 1 0 50%;
      min-height: 200px;
      background-size: cover;
      background-position: center center;
      padding: 30px;
      align-items: center;
      display: flex;
    }
  }
}

.home{
  .call_to_action{
    .cta-cols{
      min-height: 400px;
      p, h2{
        // text-align: left !important;
      }
      .list-inline{
        text-align: left !important;
        li{
          display: block;
          &:before{
            content: '●';
            display: inline-block;
            text-align: center;
            color: white;
            font-weight: 300;
            padding: 0 10px .5em 0;
            font-size: .5em;
            vertical-align: middle;
          }
        }
      }
    }
  }
}

.homepage-cta {
  align-self: end;

  .cta-content {

    @media screen and (min-width: 768px) {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }

    @for $index from 1 through 6 {
      h#{$index} {
        font-size: 5rem !important;
        line-height: 1;
        font-weight: 400;
      }
    }
    p {
      font-size: 3rem !important;
    }
  }
}


.page.contact{
  form{
    label.gfield_label{
      @extend .sr-only;
    }
    textarea, select, input:not([type="radio"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]){
      border-color: white;
      border-radius: 0;
      background-color: rgb(245, 244, 245);
      box-shadow: none;
      padding: 10px 20px;
      height: auto;
    }
    .gfield_checkbox{
      label{
        padding-left: 5px;
        font-style: italic;
      }
    }
    .btn.btn-default{
      text-transform: uppercase;
      border-radius: 0;
      font-size: 1.6rem;
      padding: 8px 25px;
    }
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      font-style: italic
    }
    ::-moz-placeholder { /* Firefox 19+ */
      font-style: italic
    }
    :-ms-input-placeholder { /* IE 10+ */
      font-style: italic
    }
    :-moz-placeholder { /* Firefox 18- */
      font-style: italic
    }
    .top_label div.ginput_container{
      margin-top: 0;
    }
  }
  .gform_wrapper{
    margin-top: 30px;
    ul.gform_fields li.gfield{
      padding-right: 0;
    }
  }
}


@media (min-width: $screen-sm-min) {
  h1{
    font-size: 3.5rem;
  }
  .brand {
    max-width: 450px;
  }
  .splash{
    .container {
      align-content: center;
      flex-flow: row nowrap;
      .head-caption{
        flex: 1 1 40%;
      }
      .head-image{
        flex: 1 1 calc(60% - 8vw);
        margin-left: 8vw;
      }
      .jumpservices{
        display: flex;
        position: absolute;
        top: 20px;
        right: 0px;
        width: 75px;
        height: 75px;
        background-color: $brand-primary;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: white;
        border-radius: 50%;
        text-transform: uppercase;
        font-size: 1.25rem;
        transition: all .5s;
        &:hover,&:active,&:focus{
          text-decoration: none;
        }
        &:hover{
          transition: all .75s;
          background: $brand-accent;
        }
        &:after{
          content: 'See all services';
        }
      }
    }
  }
  .content-blocks{
    .content-block{
      padding-left: 15px;
      padding-right: 15px;
    }
    .call_to_action{
      .cta-cols{
        .cta-col{
          position: initial;
          flex: 1 1 auto;
        }
        &.cols-fixed{
          .cta-col{
            flex: 0 0 50%;
          }
          .cta-image{
            width: calc(50vw - 30px);
            height: initial;
          }
        }
        &.cols-2{
          .cta-col{
            flex: 0 0 50%;
            &:first-child{
              .cta-image{
                right: auto;
              }
            }
            &:last-child{
              .cta-image{
                left: auto;
              }
            }
          }
          .cta-image{
            width: 50%;
            height: initial;
          }
        }
        &.cols-3{
          .cta-col{
            flex: 0 0 33.333%;
          }
        }
      }
    }
    .block-meet-the-team{
      .person-grid{
        display: flex;
        flex-flow: row wrap;
        .person,.contact-box{
          flex: 0 0 calc(33% - 20px);
          margin: 0 10px 20px;
        }
      }
    }
  }
  header{
    .navbar-primary {
      ul{
        li{
          font-size: 4rem;
        }
      }
    }
  }
}
@media (min-width: $screen-md-min) {
  .home{
    .call_to_action.what-we-do{
      .cta-cols{
        p, h2{
          text-align: center !important;
        }
        .list-inline{
          text-align: center !important;
          li{
            display: inline-block;
            &:first-child:before{
              content: '';
            }
          }
        }
      }
    }
  }

  .page.contact{
    .gform_wrapper{
      margin-top: 0;
    }
  }
  h1{
    font-size: 3.5rem;
  }
  header{
    .header-cta {
      left: 30px;
      top: 30px;
      font-size: 18px;
      .cta-book{
        display: block;
      }
    }
  }
  .content-blocks{
    .call_to_action{
      .cta-cols{
        padding: 15px 0;
      }
      .flex-cta{
        flex-direction: row;
      }
    }
    .block-subnav-grid{
      .subnav-grid{
        flex-flow: row wrap;
      }
    }
    .block-testimonial-list{
      .testimonial-grid{
        .testimonial,.grid-sizer{
          width: calc(50% - 15px);
          margin-right: 15px;
        }
      }
    }
    .content_signpost{
      background: linear-gradient(
        to bottom,
        white 35%,
        $light-grey 35%
      );
      .signpost-cols{
        &.cols-1{
          .signpost-col{
            flex: 1 1 100%;
          }
        }
        &.cols-2{
          .signpost-col{
            flex: 0 0 calc(50% - 8vw);
          }
        }
        &.cols-3{
          .signpost-col{
            flex: 0 0 calc(33.333% - 8vw);
          }
        }
      }
    }
    .flex-gallery{
      > a, > div{
        flex: 1 0 33%;
        min-height: 22.5vw;
      }
    }
    .block-single-testimonial,.block-testimonial-list{
      .quote{
        font-size: 2.8rem;
      }
    }
  }
  .lead{
    font-size: 2.8rem;
  }
  .footer-logos, .glidden{
    text-align: right;
  }

}
@media (min-width: $screen-lg-min) {
  h1{
    font-size: 4rem;
  }
  .page.contact{
    form{
      .half-width{
        width: calc(50% - 15px);
        float: left;
        clear: none;
        margin-top: 0;
        margin-right: 15px;
        &:nth-child(2n){
          width: 50%;
          margin-right: 0;
          padding-right: 0;
        }
      }
    }
  }
}
@media (min-width: $screen-xl-min) {
}

@media (min-width: 1440px) {
  .splash {
    background: linear-gradient(
      to bottom,
      white 60%,
      $light-grey 40%
    );
  }
  h1{
    font-size: 4.8rem;
  }
  .container {
      width: 1400px;
  }
  .content-blocks{
    .call_to_action{
      .cta-cols{
        width: 1400px;
      }
    }
  }
}
