// New text section layout
.content-block.text_section {
  padding: 0 !important;

  .img-margin {
    img {
      @extend .mr-5;

      @media screen and (max-width: 991px) {
        display: block;
        margin: 0 0 2rem !important;
      }
    }
  }
}

// New Chart section
.chart_section {
  .left-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media screen and (min-width: 768px) {
      max-width: 35%;
    }

    p {
      max-width: 55%;
      font-size: 1.4rem;
    }

    @for $index from 1 through 6 {
      h#{$index} {
        max-width: 75%;
        font-size: 4rem;
      }
    }
  }
}

// New flexible rows layout
.content-block.flexible_rows {
  padding: 0 !important;

  .alternate-rows {
    .row {
      &:nth-of-type(odd) {
        background-color: #E5E4E5;
      }
    }
  }

  .flex-row {
    @media screen and (min-width: 768px) {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0 !important;
    }
    .col {
      width: 100%;
      flex-grow: 1;
    }
  }
  .row {
    margin: 0 !important;
  }
}

// New flexible column layout
.content-block.flexible_columns {
  padding: 0 !important;

  @media (min-width: 992px) {
    display: flex;
    justify-content: space-between;
  }

  .flexible-column {
    width: 100%;
    padding: 1rem;

    @media (min-width: 7992px) {
      margin: 0 6.5px;

      &:first-of-type() {
        margin-left: 0 !important;
      }
      &:last-of-type() {
        margin-right: 0 !important;
      }

      @for $index from 1 through 6 {
        h#{$index} {
          font-size: 4.5rem;
        }
      }
    }

    img.aligncenter {
      height: 90px;
      width: auto;
    }

    a {
      text-transform: uppercase;
      font-size: 2rem;
    }
    &.small {
      padding: 1rem 5rem !important;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
    }
  }
}

// New page separator layout
.content-block.page_separator {
  padding: 2rem 0 !important;
}


// New Grid Layout
.content-block.grid_layout {
  .subnav-grid {
    display: flex;
    -ms-flex-flow: column wrap;
    flex-flow: row wrap;
    margin: 30px 0;

    .nav-item {
      -ms-flex: 1 1 calc(33% - 100px);
      flex: 1 1 calc(33% - 100px);
      text-align: center;
      margin: 20px 50px;

      img {
        max-height: 100px;
        margin: 0 auto 15px;
        width: auto;

        @extend .img-responsive;
      }
    }
  }
}
